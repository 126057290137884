const CARD_OFFSET_X = 60;
const BACK_CARD_OFFSET_X = 50;
const CARD_OFFSET_Y = 1.5;
const CARD_ROTATION = 0.75;
const CARD_ASPECT_RATIO = 1.4;

export {
  CARD_OFFSET_X,
  BACK_CARD_OFFSET_X,
  CARD_OFFSET_Y,
  CARD_ROTATION,
  CARD_ASPECT_RATIO,
};
